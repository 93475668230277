<template>
  <v-card>
    <v-card-title>
      <v-icon
        small
        color="white"
        class="elevation-6 pink lighten-1 pa-2"
      >
        mdi-magnify
      </v-icon>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row>
            <v-col
              cols="3"
            >
              <v-text-field
                v-model="form.patientName"
                :label="$vuetify.lang.t('$vuetify.patient.name')"
                clearable
              />
            </v-col>

            <v-col
              cols="3"
            >
              <v-text-field
                v-model="form.phone"
                :label="$vuetify.lang.t('$vuetify.common.phone')"
                clearable
              />
            </v-col>

            <v-col
              cols="3"
            >
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.common.belongToArea')"
                :value="belongToArea"
                clearable
                @click="handleBelongToAreaClick"
                @click:clear="handleBelongToAreaClear"
              />
            </v-col>

            <v-col
              cols="3"
            >
              <v-autocomplete
                v-model="form.institutionId"
                :label="$vuetify.lang.t('$vuetify.patient.institution')"
                :items="institutionItems"
                item-text="showName"
                item-value="id"
                clearable
              >
              </v-autocomplete>
            </v-col>

            <v-col
              cols="12"
            >
              <v-btn
                class="mx-2 mt-2 float-right"
                color="primary"
                fab
                dark
                small
                @click="handleSearch"
              >
                <v-icon dark>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </v-col>

          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-dialog
      v-model="addressDialog"
      persistent
      max-width="800"
    >
      <v-card
        v-if="addressDialog"
      >
        <v-card-title class="headline">
          {{ $vuetify.lang.t('$vuetify.common.belongToArea') }}
        </v-card-title>
        <v-card-text>
           <v-row>
            <v-col
              cols="4"
            >
              <v-select
                v-model="form.province"
                :label="$vuetify.lang.t('$vuetify.region.province')"
                :items="provinceItems"
                menu-props="auto"
                item-text="name"
                item-value="id"
                clearable
                return-object
                @change="handleProvinceChange"
              ></v-select>
            </v-col>

            <v-col
              cols="4"
            >
              <v-select
                v-model="form.city"
                :label="$vuetify.lang.t('$vuetify.region.city')"
                :items="cityItems"
                menu-props="auto"
                item-text="name"
                item-value="id"
                clearable
                return-object
                @change="handleCityChange"
              ></v-select>
            </v-col>

            <v-col
              cols="4"
            >
              <v-select
                v-model="form.county"
                :label="$vuetify.lang.t('$vuetify.region.county')"
                :items="countyItems"
                menu-props="auto"
                item-text="name"
                item-value="id"
                clearable
                return-object
                @change="handleCountyChange"
              ></v-select>
            </v-col>

            <v-col
              cols="6"
            >
              <v-select
                v-model="form.town"
                :label="$vuetify.lang.t('$vuetify.region.town')"
                :items="townItems"
                menu-props="auto"
                item-text="name"
                item-value="id"
                clearable
                return-object
                @change="handleTownChange"
              ></v-select>
            </v-col>

            <v-col
              cols="6"
            >
              <v-select
                v-model="form.village"
                :label="$vuetify.lang.t('$vuetify.region.village')"
                :items="villageItems"
                menu-props="auto"
                item-text="name"
                item-value="id"
                clearable
                return-object
                @change="handleVillageChange"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            @click="addressDialog = false"
          >
            {{ $vuetify.lang.t('$vuetify.common.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        form: {
          patientName: null,
          phone: null,
          province: null,
          city: null,
          county: null,
          town: null,
          village: null,
          institutionId: null
        },
        addressDialog: false,
        provinceItems: [],
        cityItems: [],
        countyItems: [],
        townItems: [],
        villageItems: [],
        institutionItems: []
      }
    },
    computed: {
      ...mapGetters({
        regions: 'region/regions',
        departments: 'department/departments'
      }),

      belongToArea () {
        let res = ''
        if (this.form.province) {
          res += `${this.form.province.name}`
        }
        if (this.form.city) {
          res += `-${this.form.city.name}`
        }
        if (this.form.county) {
          res += `-${this.form.county.name}`
        }
        if (this.form.town) {
          res += `-${this.form.town.name}`
        }
        if (this.form.village) {
          res += `-${this.form.village.name}`
        }
        return res
      }
    },
    created () {
      this.initialize();
    },
    methods: {

      ...mapActions ({
        regionQueryAll: 'region/queryAll',
        departmentQueryAll: 'department/queryAll'
      }),

      initialize () {
        this.getRegion({parentId: ''})
        this.departmentQueryAll().then(() => {
          this.institutionItems = this.departments
        })
      },

      getRegion (params = {}, target = 'provinceItems') {
        return new Promise((resolve, reject) => {
          this.regionQueryAll(params).then(() => {
            this[target] = this.regions
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },

      handleProvinceChange (item) {
        this.cityItems = []
        this.countyItems = []
        this.townItems = []
        this.villageItems = []
        this.form.city = null
        this.form.county = null
        this.form.town = null
        this.form.village = null
        if (item) {
          this.getRegion({  parentId: item.id }, 'cityItems')
        }
      },

      handleCityChange (item) {
        this.countyItems = []
        this.townItems = []
        this.villageItems = []
        this.form.county = null
        this.form.town = null
        this.form.village = null

        if (item) {
          this.getRegion({  parentId: item.id }, 'countyItems')
        }
      },

      handleCountyChange (item) {

        this.townItems = []
        this.villageItems = []
        this.form.town = null
        this.form.village = null

        if (item) {
          this.getRegion({  parentId: item.id }, 'townItems')
        }
      },

      handleTownChange (item) {
        this.villageItems = []
        this.form.village = null

        if (item) {
          this.getRegion({  parentId: item.id }, 'villageItems')
        }
      },

      handleVillageChange () {

      },

      handleSearch() {
        const data = {}
        for (let k in this.form) {
          if (this.form[k]) {
            data[k] = typeof this.form[k] === 'object' ? this.form[k].id : this.form[k]
          }
        }
        this.$emit('submit', data)
      },

      handleBelongToAreaClick () {
        this.addressDialog = true
      },

      handleBelongToAreaClear () {
        this.form.province = null
        this.form.city = null
        this.form.county = null
        this.form.town = null
        this.form.village = null
      }
    }
  }
</script>

<style lang="scss" scoped></style>
